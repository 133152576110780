import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2022-stories/more-stories"

import Hero from '../../images/spring-summer-2022/presidents-message/hero.jpg';
import framSignature from "../../images/spring-summer-2022/presidents-message/fram-signature.svg";

import socialBanner from "../../images/spring-summer-2022/social-banners/presidents-message.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'President’s Message';
    var pageDesc = 'During tough times, a Titan alumna spreads hope through feel-good holiday stories.';
    var slug = 'presidents-message';

    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white',
      height: '85vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p><span className="intro-text">Both as President and as a first-generation college graduate,</span> I often have the honor of meeting with the more than 20,000 first-generation students who call our campus home. The first thing I tell these students is simple: “We at Cal State Fullerton see you. I see you.” Indeed, in our classrooms and around our community, our first-generation students are as visible as they are impactful; as generous as they are driven; as tenacious as they are resilient.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Truth be told, our first-generation students are impossible to miss. You will see them working multiple jobs and supporting their families. You will see them navigating the transition from military soldier to college student. You will see them leading our student government and partnering with faculty on groundbreaking research. And if you look closely enough, you will see them walking to class under the weight of not just the books in their backpacks, but the family legacies on their shoulders.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>By definition, the more than 5,000 first-generation Titans who cross our commencement stage every year may be the first in their families to reach that academic achievement, but they will not be the last. This speaks to the eternal impact a first-generation graduate has, first in transforming his or her own life and community, and then in transforming the lives and communities of the countless family members who will follow in his or her footsteps for generations to come.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Of course, we at CSUF are proud of the support and services we provide for all first-generation students to manifest that success — from the “I Am First” Career Preparation Program to our TRIO Student Support Services. But no matter how much our first-gen students receive from these and many other programs designed to serve them, we at Cal State Fullerton receive something far greater: the incredible strengths of resilience, courage and leadership these students bring to our classrooms and community — in both good and challenging times.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>With all that in mind and in the face of this ongoing pandemic, we are proud to feature  first-generation students, alumni, faculty and staff as the cover story of this issue of Titan magazine. To be sure, not every success story you are about to read focuses directly on first-generation students, but I can assure you that, like every other achievement at Cal State Fullerton, first-generation Titans (and the faculty and staff who support them) helped make them happen.</p>
          </ScrollAnimation>
 
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p style={{lineHeight: '1.2em'}}>
            Sincerely,<br /><br />
            <img src={framSignature} alt="Fram Virjee" width="200px" /><br />
            Framroze M. Virjee, JD<br />
            President<br />
            California State University, Fullerton
          </p>
          </ScrollAnimation>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 